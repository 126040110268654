import { FunctionComponent, useState } from 'react';
import dynamic from 'next/dynamic';

import Searchbar from 'components/modules/public/layout/forms/SearchbarForm';
import SectionHero from 'components/modules/public/layout/sections/Hero';

import StyledComponent from './styles';
import { Props } from './types';

const Portal = dynamic(() => import('components/modules/public/layout/modals/Portal'), { ssr: false });
const SearchbarModal = dynamic(() => import('components/modules/public/layout/modals/SearchbarModal'), { ssr: false });

const PublicSectionHeroVariantSearch: FunctionComponent<Props> = ({ translations, bottomChildren }) => {
    const [searchQuery, setSearchQuery]: [string, Function] = useState(null);
    const {
        background,
        backgroundMobile,
        headline,
        subheadline,
    } = translations;

    const onSearch = ({ query }: { query: string }) => setSearchQuery(query || ' ');
    const onCloseModal = () => setSearchQuery(null);

    return (
        <StyledComponent className="public-section-hero-variant-search">
            <SectionHero
                background={background}
                backgroundMobile={backgroundMobile}
                headline={headline}
                subheadline={subheadline}
            >
                <div className="searchbar">
                    <Searchbar onSuccess={onSearch} />
                </div>

                {searchQuery && (
                    <Portal>
                        <SearchbarModal
                            modalProps={{
                                onClose: () => onCloseModal(),
                            }}
                            formProps={{
                                initialValues: {
                                    search: searchQuery,
                                },
                            }}
                        />
                    </Portal>
                )}

                {bottomChildren}
            </SectionHero>
        </StyledComponent>
    );
};

export default PublicSectionHeroVariantSearch;
