import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
    position: relative;
    width: 100%;

    .searchbar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2em;
        z-index: 1;

        .public-layout-forms-searchbar-form {
            max-width: 30em;
            width: 90%;
        }
    }

    @media all and (max-width: ${vars.mobileL}) {
        .searchbar {
            .public-layout-forms-searchbar-form {
                input {
                    font-size: 16px;
                }
            }
        }
    }
`;
